@import "../public/assets/fontawesome";
@import '../public/assets/scss/bootstrap';
@import "../public/assets/scss/sanfrancisco";
@import "../public/assets/scss/arial-black";
@import "../public/assets/scss/theme/variables";

body {
  font-family: 'IBM Plex Sans' !important;
}

body.theme-dark {
  background-color: $theme-dark !important;
  color: $white;

  
  .mobile-menu {
    background-color: $grey-middle !important;
    color: #828282 !important;

    .menu-icon, .menu-text {
      color: $hashtag-font !important;
      
      &.active {
        color: $white !important;
        
      }
    }
  
  
  }

  .text-default {
    color: $theme-silver;
  }
  

  .text-disabled * {
    color: #555 !important;
  }
  

  .modal-content {
    background-color: $theme-dark !important;
  }

    
  .large-header {
    color: $white !important;
  }

  .footer-section {
    // background-color: $grey-middle !important; 
  }


  .header-logo {
    color: $white;
  }

  .btn-solid {
    border-color: $white !important;
    background-image: linear-gradient(30deg, $theme-dark 100%, transparent 100%) !important;

    &.bg-none {
      background: none !important;
    }
  
    // background-color: $theme-dark !important;

    &.telegram-btn {
      background-image: linear-gradient(30deg, $theme-telegram 100%, transparent 100%) !important;
      border-color: $theme-telegram !important;
    }

  }

  .fullscreen-modal .modal-header .close {
    color: $theme-silver;
  }

      
    .button-selector {
      color: $white;
      @extend .border-grey;

      &.default {
          
      }

      &.active {
        background-color: $white !important;
        color: $black !important;
      }
    }
  .pager-point {
    color: $theme-silver;
    &.active {
      color: $white;
    }
  }
  
}


.border-gold {
  border-color: gold;
}

.rounded-xl {
  border-radius: .5em !important;
}

.btn-solid {
  &.bg-none {
    background: none !important;
  }
}

.footer-section {
  @extend .bg-black;
}

.text-default {
  color: $black;
}

.text-disabled {
  @extend .text-silver;
}

.header-logo {
  color: $black;
}

.button-selector {
  color: $black;
  &.default {
    @extend .border-grey;
  }
  &.active {
    color: $white;
    @extend .btn-dark;
  }
}

.block-squared:before {
  content: "";
  display: block;
  padding-top: 100%;
  /* initial ratio of 1:1*/
}

.block-vertical-rect:before {
  content: "";
  display: block;
  padding-top: 132%;
  /* initial ratio of 1:1*/
}

.block-horizontal-rect:before {
  content: "";
  display: block;
  padding-top: 68%;
  /* initial ratio of 1:1*/
}

.block-horizontal-flat:before {
  content: "";
  display: block;
  padding-top: 45%;
  /* initial ratio of 1:1*/
}

.player-wrapper {
  width: auto; // Reset width
  height: auto; // Reset height
}
.react-player {
  padding-top: 56.25%; // Percentage ratio for 16:9
  position: relative; // Set to relative
}

.react-player > div {
  position: absolute; // Scaling will occur since parent is relative now
  top: 0;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  line-height: 1.5 !important;
}

.text-strike {
  text-decoration: line-through;
}

.large-header {
  font-size: 1.9rem;
  font-weight: 500;
}
.standart-header {
  font-size: 1rem;
  // font-weight: 500;
}

.modal-header,
.modal-footer {
  border: 0;
}
.modal-footer {
  justify-content: initial;
}

.modal-title {
  font-weight: bold;
  // text-transform: uppercase !important;
}

.btn {
  // text-transform: initial !important;
  // font-size: 1.1rem !important;
}

.main-wrapper {
  height: 80vh;
}

.btn:disabled {
  cursor: default !important;
  opacity: 0.2;
}

.slick-dots li button:before {
  font-size: 1.5rem !important;
}

.url {
  text-decoration: underline;
  cursor: pointer;
  &:hover {
    text-decoration: none;
  }
}

.bg-yellow {
  background-color: yellow !important;
}

.bg-green {
  background-color: #83e3bd !important;
}

.bg-magenta {
  background-color: #f828fb !important;
}

.bg-grey {
  background-color: #eee !important;
}

.bg-dark {
  background-color: #bbb !important;
}

.bg-black {
  background-color: #000 !important;
}


.mobile-menu {
  bottom: 0px;
  left: 0;
  // margin-bottom: -3px;
  z-index: 99;
  background-color: white;
  width: 100%;
  text-align: center;

  .menu-icon {
    @extend .text-dark;
  }

  .menu-text {
    @extend .text-secondary;
  }

  .button-menu { 
    i {
      font-size: 20px;
    }
  }
  // box-shadow: 8px 0px 8px #000;
  // height: 75px;
  // color: white;
  .row > div {
    // font-size: 20px;
    a {
      padding-top: 10px;
      display: inline-block;
      position: relative;
      // color: white !important;
    }

    .ultra-small {
      font-size: 10px;
    }

    
    &.active-mobile-menu {
      a, small {
        color: rgb(220, 53, 69) !important;
      }
      color: rgb(220, 53, 69) !important;
    }
  }
}

.mobile-menu-space {
  height: 75px;
}


.btn {
  text-transform: initial !important;
}

.btn-dark {
  border-color: #000;
  background-color: #000;
}

.pager-point {
  color: $theme-silver;
  &.active {
    color: $theme-dark;
  }
}

.product-photos {
  .swiper-pagination-bullet {
    background-color: #aaa !important;
    opacity: 1 !important;
  }

  .swiper-pagination-bullet-active {
    background-color: #fff !important;
  }

  .swiper-slide {
    width: 100% !important;
  }
}

.faq-content {
  width: 100%;
  p {
    margin-bottom: 0;
  }

  a {
    color: black;
  }

  img {
    max-width: 100%;
  }
}

.index-content {
  width: 100%;
  p {
    margin-bottom: 0;
  }

  a {
    color: white;
  }
}

.fast-feedback {
  // height: 200px;

  .swiper-pagination-bullet {
    background-color: #aaa !important;
    opacity: 1 !important;
  }

  .swiper-pagination-bullet-active {
    background-color: #000 !important;
  }

}

.fullscreen-modal {
  .modal-dialog {
    margin: 0;
  }
  .modal-content {
    min-width: 100vw !important;
    min-height: 100vh !important;
  }
}

.items-images {
  position: relative;
  // height: 20vh;
  .item {
    z-index: 10;
    position: sticky;
    img {
      width: 120px;
      height: 120px;
      // border: 1px solid #ddd;
      &.bordered {
        // border: 1px solid;
      }
    }
    &.small img {
      width: 100px;
      height: 100px;
    }
    &.micro img {
      width: 30px;
      height: 30px;
    }
    .emoji {
      position: absolute;
      left: 0;
      width: 100%;
      vertical-align: middle;
      text-align: center;
      line-height: 120px;
    }
    &.small .emoji {
      line-height: 85px;
    }

    &.micro .emoji {
      line-height: 35px;
    }

    &.second {
      z-index: 9;
      margin-left: -20px;
    }

    &.second.small {
      margin-left: -15px;
    }
  }
}

.content-html {
  p { margin-bottom: 0;}
  a {color: black; }
}


.info-modal-full div.modal-title { 
  position: absolute;
  width: 100%;
  height: 100%;
}

.modal-header.info-modal-empty {
  padding: 0 !important;
}

.info-modal-empty div.modal-title { 
  // position: absolute;
  width: 100%;
  height: 100%;
}

.text-silver , .text-silver .hashTag {
  color: #cccccc !important;
}

.hashTag {
  color: $hashtag-font !important;
}

.border-grey {
  border-color: #ced4da !important;
}

.giphy-image {

  display: block;
  height: 220px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;

}

.meme-image {

  display: block;
  width: 100%;
  height: auto;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;

}


.fullscreen-modal .modal-header .close {
  font-size: 3rem;
  font-weight: 200;
}

.soon-corner {
  position: absolute;
  top: 0;
  right: 0;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 80px 80px 0;
  border-color: transparent #000 transparent transparent;
}

.soon-corner .text {
  display:block;
  margin: 15px;
  width: 80px;
  color:#ffffff;
     -ms-transform: rotate(45deg);
    -webkit-transform: rotate(45deg); 
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    -sand-transform: rotate(45deg);
    transform: rotate(45deg);
}

.feedback-truncate {
  text-overflow: ellipsis;

  /* Needed to make it work */
  overflow: hidden;

}